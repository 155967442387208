@use "../../../sass/base" as *;

.project-learnings-section__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    overflow: auto;

    &__cursor {
        width: 0.8rem;
        aspect-ratio: 1;
        background-color: white;
        mix-blend-mode: difference;

        border-radius: 50%;

        position: fixed;
        transform: translate(-50%, -50%);
        z-index: 2;
        pointer-events: none;

        transition: scale 250ms ease;

        // if mobile then dont show the cursor
        @media screen and (max-width: 500px) {
            display: none;
        }
    }

    &:has(button:hover) &__cursor {
        scale: 0;
    }

    & > button {
        z-index: 1;

        position: absolute;
        top: $padding-md;
        right: $padding-md;

        background-color: rgba(black, 0.9);
        border-radius: 100vh;

        & > div > span > span {
            display: flex;
            gap: $gap-extra-sm;
            align-items: center;

            font-family: $ff-roboto;

            & > svg {
                transition: transform 200ms ease-in-out;
            }

            & > span {
                font-weight: 600;
            }
        }

        &:hover,
        &:active,
        &:focus {
            & svg {
                transform: translateX(-3px) scale(1.2);
            }
        }
    }

    &__head-container {
        display: flex;
        flex-direction: column;
        gap: $gap-sm;
        padding-inline: $padding-md;
        padding-block-start: $padding-lg;
        padding-block-end: $padding-extra-lg;

        &__main-heading {
            display: flex;
            flex-wrap: wrap;
            font-size: clamp(3rem, 15vw, 8rem);
            font-weight: 600;
        }

        &__learning {
            line-height: 1;
            display: flex;
            align-items: center;
            gap: $gap-sm;
        }
    }

    &__info-cards-container {
        position: relative;
        background-color: rgba($faded-gray, 0.15);

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        column-gap: $gap-lg;
        row-gap: calc($gap-lg + 0.8rem);

        padding-block: $padding-lg;

        &__seperator-line {
            position: absolute;
            top: 0;
            width: 100%;
            height: 2px;
            background-color: rgba(white, 0.5);
            z-index: 1;
        }

        &:has(&__card:hover) &__card {
            opacity: 0.5;
        }

        &__card {
            overflow: hidden;
            padding-inline: $padding-sm;

            &:hover {
                opacity: 1 !important;
            }
            transition: opacity 550ms ease;

            &__card-title {
                position: relative;
                font-weight: 600;
                font-size: 1.05rem;
                text-transform: capitalize;

                &::before {
                    position: absolute;
                    content: "";
                    width: 1rem;
                    height: 2px;
                    border-radius: 100vw;
                    background-color: rgba(white, 0.4);
                    top: -0.2rem;
                    left: -0.3rem;
                }

                margin-block-end: $gap-lg;
                @media screen and (max-width: 59.06rem) {
                    margin-block-end: $gap-sm;
                }
            }

            &__card-desc {
                font-size: 0.8rem;
            }
        }
    }
}
