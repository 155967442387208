@use "./variables" as *;

.styled-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: max-content;

    padding: 4rem;

    background-color: rgb(23, 23, 23);

    overflow: hidden;

    text-transform: capitalize;
    position: relative;

    isolation: isolate;

    color: white;

    font-family: $ff-roboto;
    .btn-overlay {
        position: absolute;
        inset: 0;

        border-radius: inherit;
        background-color: #4a5fd6;

        transform: translateY(101%);
        z-index: -1;
    }
}

.width-container {
    max-width: $max-container-width;
    margin-inline: auto;
}
.padding-container {
    padding-inline: $section-inline-padding;
    // 550px
    @media screen and (max-width: 34.37rem) {
        padding-inline: 0;
    }
}

.rounded-div-container {
    position: absolute;
    top: 0;
    width: 100%;

    // default bg color
    overflow: hidden;
    background-color: $dark-faded;
    height: 70px;

    .rounded-div {
        z-index: 99;
        box-shadow: 0 0 10px 0px $dark-faded;
        background-color: white;
        position: absolute;
        bottom: 0;
        inset-inline: -3vw;
        height: 100%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }
}
