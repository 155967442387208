@use "../../../sass/base" as *;
.main-tablet-container {
    padding: 10vw;
    width: 100%;
    //500px
    @media screen and (max-width: 31.25rem) {
        padding-inline: 1em;
        padding-block: $padding-extra-lg;
    }

    display: flex;
    justify-content: center;

    &__design-asset {
        max-width: min(60%, 45rem);
        margin-inline: auto;
    }
}
