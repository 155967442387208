@use "../../sass/base" as *;

.loading-page-transition-overlay {
    position: fixed;
    inset: 0;
    background-color: black;
    color: white;
    z-index: 999;

    display: grid;
    place-items: center;

    transform: translateY(-100%);

    & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: $gap-md;

        & > span {
            font-size: $fs-md;
        }
    }
}
.main-error-container[data-type="success"] {
    background-color: rgb(8, 140, 8);
}
.main-error-container[data-type="error"] {
    background-color: rgb(173, 26, 26);
}

.main-contact-page-container {
    display: flex;
    gap: $gap-extra-lg;
    min-height: 100vh;
    color: black;
    padding-inline: 5%;
    padding-bottom: 1rem;

    //800px
    @media screen and (max-width: 50rem) {
        flex-direction: column;
        gap: $gap-extra-lg;
    }

    & > * {
        flex: 1;
    }

    &__main-info-container {
        display: flex;
        justify-content: center;
        gap: calc($gap-lg + 0.5rem);
        flex-direction: column;

        transform: translateY(-10%);
        margin-top: 0;

        @media screen and (max-width: 50rem) {
            gap: $gap-lg;
            transform: translateY(0);
            margin-top: 2rem;
        }

        &__info-container {
            &__main-heading {
                font-weight: 600;
                font-size: calc($fs-lg + 0.7rem);
                text-transform: capitalize;
            }
            p {
                font-size: $fs-sm;
                font-weight: 400;
            }
        }

        & > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            gap: 0.1rem;

            & > span:nth-child(1) {
                font-size: $fs-md;
                font-weight: 600;
            }
            & > span:nth-child(2) {
                font-size: $fs-sm;
                font-weight: 400;
            }
        }
    }

    form {
        // background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: calc($gap-lg + 0.3rem);

        // ~730px
        @media screen and (max-width: 40rem) {
            gap: calc($gap-lg + 0.6rem);
        }

        .styled-btn-container {
            display: flex;
            justify-content: flex-end;
            padding-right: 20%;

            position: relative;

            &::after {
                content: "";
                z-index: -1;

                position: absolute;
                top: 50%;
                transform: translateY(-50%) translateX(10%);

                background-color: rgba(black, 0.08);
                width: 40%;
                height: 3px;
                border-radius: 100vh;

                @media screen and (max-width: 50rem) {
                    width: 60%;
                }
            }
        }
    }
}
