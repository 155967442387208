@use "../../../sass/base" as *;

.desktop-mockup {
    background-color: #f0f0f0;

    &__design-asset {
        width: min(95%, 60rem);
        margin-inline: auto;
        display: block;
        height: auto;
    }
}
