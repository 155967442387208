@use "../../sass/base" as *;

.mobile-menu-toggle-btn {
    position: absolute;
    top: $padding-md;
    right: $padding-lg;
    z-index: 99999;
    color: white;

    font-size: 0.9rem;

    .illustration {
        position: absolute;
        left: -0.8rem;
        top: 50%;
        transform: translateY(-50%);
    }
    .illustration.close {
        left: -1rem;
    }

    @media screen and (min-width: 26.5rem) {
        display: none;
    }
}

.body-overlay {
    position: fixed;
    background-color: rgba(black, 0.8);
    inset: 0;

    z-index: 999;

    visibility: hidden;
    opacity: 0;
}

.menu-toggle-btn {
    width: 4.5rem;
    height: 4.5rem;

    background-color: var(--main-color, rgb(23, 23, 23));

    border-radius: 50%;

    position: fixed;
    right: 5%;
    top: 5%;

    display: block;

    z-index: 99999;
    overflow: hidden;

    .btn-overlay {
        position: absolute;
        inset: 0;
        border-radius: 50%;

        border-radius: 50%;
        background-color: var(--secondary-color, $primary-blue);

        transform: translateY(100%);
    }
    .menu-lines-container {
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.3rem;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        transition: 200ms ease;
        pointer-events: none;
    }

    .menu-line {
        width: 1.5rem;
        height: 0.09rem;
        background-color: white;

        transition: transform 350ms ease;
        pointer-events: none;
    }
    .menu-line:nth-child(2) {
        height: 0.05rem;
        background-color: rgb(236, 236, 236);
    }

    &.open-menu {
        .menu-lines-container {
            gap: 0;
        }
        .menu-line {
            position: absolute;
        }
        .menu-line:nth-child(2) {
            background-color: white;
            transform-origin: center;
            transform: rotate(45deg);
            height: 0.09rem;
        }
        .menu-line:nth-child(1) {
            transform: rotate(-45deg);
        }
    }
}

.main-navigation-container {
    $padding-inline: 2rem;

    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(21, 21, 21);
    z-index: 9999;

    transform: translateX(100%);
    transform-origin: right;

    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;

    position: fixed;
    right: 0;
    inset-block: 0;

    width: 40vw;
    max-width: 30rem;

    display: flex;
    flex-direction: column;
    color: white;

    padding: $padding-inline;
    padding-top: 6%;

    .styled-span {
        font-size: 0.6rem;
        font-weight: 500;
        text-transform: uppercase;
        color: $faded-gray;
    }

    .top-head-container {
        padding: 1rem;
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $faded-gray;

            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .nav-links-container {
        padding-block: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        margin-top: 1rem;

        .nav-link {
            display: flex;
            align-items: center;
            gap: 0.8rem;

            transform: translateX(50%);

            &.active {
                .circle {
                    transform: scale(1);
                }
            }

            &.active:hover {
                .circle {
                    transform: scale(1);
                }
            }

            &:hover {
                .circle {
                    transform: scale(0.5);
                }
            }

            .circle {
                $cirlce-size: 0.7rem;
                display: block;
                width: $cirlce-size;
                height: $cirlce-size;
                background-color: white;
                border-radius: 50%;

                transition: transform 250ms ease-in-out;
                transform: scale(0);
            }

            a {
                font-size: 2.5rem;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }

    .bottom-head-container {
        margin-top: auto;
        .socials-container {
            margin-top: 0.8rem;
            display: flex;
            justify-content: space-between;
            font-size: 0.8rem;
            font-weight: 500;
            font-family: $ff-open-sans;
            text-transform: uppercase;

            a {
                position: relative;
                padding-bottom: 0.2rem;

                &::after {
                    content: "";
                    height: 1.5px;

                    position: absolute;
                    bottom: 0;
                    inset-inline: 0;

                    background-color: white;

                    transform: scaleX(0);
                    transition: 250ms ease;
                }
                &:hover {
                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 56.5rem) {
        width: 60vw;
        padding-top: 10%;
    }
    @media screen and (max-width: 40rem) {
        width: 100vw;
        padding-top: 18%;
        padding-bottom: 4%;

        .nav-links-container {
            .nav-link {
                flex-direction: row-reverse;
                justify-content: space-between;
            }
        }
        .bottom-head-container {
            position: relative;
            padding-top: 1rem;
            &::before {
                content: "";
                display: block;
                height: 1px;
                background-color: $faded-gray;

                position: absolute;
                top: 0;
                inset-inline: 0;
            }
        }
    }
}
