@use "../../sass/base" as *;

.animated-overlay {
    position: fixed;
    inset: 0;
    background-color: black;

    z-index: 9999999;

    min-height: 100vh;

    color: white;

    display: grid;
    place-items: center;
    transform: translateY(0);

    &__text:first-of-type {
        opacity: 1;
    }

    &__text {
        display: flex;
        align-items: center;
        gap: $gap-sm;

        white-space: nowrap;

        position: absolute;
        left: 50%;
        top: 50%;

        opacity: 0;

        font-size: 2.6rem;

        transform: translate(-50%, -50%);
    }

    &__bottom-curve {
        position: absolute;

        inset-inline: -15vw;
        background-color: inherit;
        height: 200px;
        bottom: 0;
        transform: translateY(32%);

        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }
}
