@use "../../../sass/base" as *;

.single-work-info-live-link-section {
    margin-block: $padding-lg;
    margin-bottom: $padding-extra-lg;

    padding-inline: $padding-lg;
    min-height: 80vh;

    @media screen and (max-width: 500px) {
        min-height: 50vh;
        padding-inline: $padding-md;
    }

    position: relative;

    display: grid;
    place-items: center;

    transform-style: preserve-3d;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &__link {
        position: absolute;
        top: -5%;
        transform: translateY(-40%);
        right: 5%;

        .icon {
            position: absolute;

            top: 45%;
            right: 1.8rem;
            font-size: 0.9rem;

            color: white;
            transform: rotate(-40deg);
            pointer-events: none;

            transition: opacity 300ms ease, transform 450ms ease;
        }

        &:hover {
            .icon {
                transform: rotate(-40deg) translateX(10%);
            }
        }
    }

    &__github-link {
        position: absolute;
        bottom: -20%;
        left: 10%;

        display: grid;
        place-items: center;

        .icon {
            position: absolute;
            color: black;
            font-size: 5rem;

            pointer-events: none;
        }
    }
}
