@use "../../sass/base" as *;

.about-section {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    gap: min(10%, 2.4rem);

    padding-top: $padding-extra-lg;

    // 700px
    @media screen and (max-width: 43rem) {
        grid-template: auto / 1fr;
        gap: $gap-lg;
    }

    &__head-container {
        font-size: $fs-lg;
        font-weight: 400;

        &__text-container {
            overflow: hidden;

            .text {
                display: block;
                transform: translateY(130%);
            }
        }
    }

    &__info-container {
        display: flex;
        flex-direction: column;
        gap: $gap-extra-lg;

        p {
            font-size: $fs-sm;
            opacity: 0;
            transform: translateY(50%);
        }
        a {
            transform: translateY(40%);
        }

        // 700px
        @media screen and (max-width: 43rem) {
            gap: 0;
            a {
                width: max-content;
                margin-left: 50%;
            }
        }
    }
}
