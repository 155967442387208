@use "../../sass/base" as *;

.page-transition-overlay {
    --curve-height: 0;

    position: fixed;
    inset: 0;
    background-color: black;

    z-index: 999999;

    min-height: 100vh;

    color: white;

    display: grid;
    place-items: center;

    font-size: calc($fs-lg + 0.7rem);
    font-family: $ff-roboto;
    text-transform: capitalize;

    transform: translateY(clac(100% + var(--curve-height)));

    &__text-container {
        display: flex;
        gap: calc($gap-sm + 0.3rem);
        align-items: center;
    }

    &__top-curve,
    &__bottom-curve {
        position: absolute;
        height: var(--curve-height);
        inset-inline: -10%;
        background-color: inherit;
    }

    &__top-curve {
        top: 0;
        transform: translateY(-99%);

        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
    }
    &__bottom-curve {
        position: absolute;
        bottom: 0;
        transform: translateY(99%);

        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }
}
