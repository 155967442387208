@use "../../sass/base" as *;

.main-contact-container {
    background-color: $dark-faded;
    width: 100%;
    margin-top: calc($padding-lg + 4rem);

    position: relative;

    padding-bottom: calc($padding-section - 10rem);
    padding-top: calc($padding-section - 4rem);

    overflow: hidden;

    .width-container {
        max-width: 70%;
        // 700px
        @media screen and (max-width: 43rem) {
            max-width: 90%;
        }
    }

    @media screen and (max-width: 43rem) {
        padding-block: calc($padding-extra-lg + 2rem);
    }

    &__info-container {
        color: white;
        display: flex;
        flex-direction: column;
        gap: calc($gap-lg + 0.9rem);

        //700px
        @media screen and (max-width: 43rem) {
            gap: calc($gap-lg + 1.5rem);
        }
        //500px
        @media screen and (max-width: 31.25rem) {
            gap: $gap-extra-lg;
        }

        &__head {
            display: flex;
            flex-direction: column;

            font-size: clamp($fs-lg + 0.8rem, 5vw, $fs-lg + 1.3rem);
            font-weight: 500;

            & > div {
                display: flex;
                align-items: center;
                gap: $gap-md;

                .my-img {
                    width: 5rem;
                    aspect-ratio: 1;

                    border-radius: 50%;
                    background-color: $faded-gray;
                }
            }
        }

        .line {
            width: 100%;
            height: 1px;
            background-color: $faded-gray;
            position: relative;

            a {
                position: absolute;
                right: 0;
                transform: translateY(-50%) translateX(-9rem);
            }
        }

        &__contact-btn-container {
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2rem;

            button {
                font-size: 1.1rem;
                color: white;

                &:hover,
                &:focus-visible {
                    .text-container {
                        &__main-text-container {
                            &__bottom-line {
                                transform: scale(1);
                            }
                        }
                    }
                    .abs-text {
                        transform: translateX(0);
                        opacity: 0.6;
                    }
                }

                .text-container {
                    display: flex;
                    gap: $gap-lg;
                    align-items: flex-end;
                    position: relative;

                    &__main-text-container {
                        display: flex;
                        gap: $gap-extra-sm;
                        flex-direction: column;

                        &__bottom-line {
                            background-color: rgba(white, 0.3);
                            width: 100%;
                            height: 1.5px;
                            border-radius: 100vh;

                            transform: scaleX(0.14);
                            transform-origin: left;
                            transition: transform 350ms ease-in-out;
                        }
                    }

                    & > span {
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-transform: capitalize;

                        position: absolute;
                        pointer-events: none;

                        width: max-content;

                        transform: translateX(-1.4rem);
                        transition: transform 300ms ease-in-out,
                            opacity 300ms ease-in-out;
                        transition-delay: 180ms;

                        opacity: 0;
                    }
                }
            }
        }
    }
}
