@use "../../../sass/base" as *;

.single-work-info-header {
    padding-block: $padding-extra-lg;

    &__brand-name {
        font-size: calc($fs-lg + 0.8rem);
        font-weight: 500;
        margin-bottom: $gap-md;
        display: block;
    }

    &__info-container {
        display: flex;

        //500px
        @media screen and (max-width: 31.25rem) {
            flex-direction: column;
            gap: $gap-sm;
        }

        & > div {
            display: flex;
            flex-direction: column;

            text-transform: capitalize;
            font-size: $fs-sm;
            font-weight: 500;

            .head {
                font-size: 0.7rem;
                font-weight: 500;
                opacity: 0.5;
            }

            padding-inline: $padding-lg;
            padding-block: $padding-extra-sm;

            &:not(:first-child) {
                border-left: 1.3px solid rgba(black, 0.2);
            }
            &:first-child {
                padding-inline-start: 0;
            }

            @media screen and (max-width: 31.25rem) {
                &:first-child {
                    padding-inline: $padding-lg;
                    border-left: 1.3px solid rgba(black, 0.2);
                }
            }
        }
    }

    &__work-desc {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        margin-block-start: $padding-lg;

        & > .head {
            font-size: 0.7rem;
            font-weight: 500;
            opacity: 0.7;
            text-transform: capitalize;
        }

        p {
            font-size: min($fs-sm, 0.8rem);
            font-weight: 500;
        }
    }
}
