.mobile-mockup-container {
    position: relative;

    width: 20%;
    aspect-ratio: 1 / 2.05;

    overflow: hidden;

    //500px
    @media screen and (max-width: 31.25rem) {
        width: 60%;
    }

    &__mobile-mockup-img {
        position: absolute;

        height: 100%;
        z-index: 9;
    }
    &__loader-container {
        position: absolute;
        width: 100%;
        height: 100%;

        display: grid;
        place-items: center;
    }
    &__design-asset {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 12%;
        padding: 5px;
    }
    video {
        object-fit: contain;
    }
}
